<template>
  <div>
    <div class="result-container">
      <div
        v-if="!showCamera"
        class="container"
      >
        <h1 class="text-center text-uppercase">
          Успешно
        </h1>
        <br>
        <br>
        <div class="row">
          <div class="col-sm-12 col-md-12">
            <div class="info-card info-card--success">
              <div class="info-card_icon">
                <img
                  class="info-card_img-icon"
                  src="https://atknin.com/media/ygeo_success.svg"
                  alt="success"
                >
              </div>
              <h2 class="info-card_label">
                {{ form.card_type }}
              </h2>
              <div class="info-card_message">
                <div class="title">
                  <p>Номер карты:</p>
                </div>
                <div
                  v-if="form.account_number"
                  class="result"
                >
                  <h4 style="color: #4caf50">
                    {{ form.account_number }}
                  </h4>
                </div>
                <div
                  v-if="form.card_number"
                  class="result"
                >
                  <h4 style="color: #4caf50">
                    {{ form.card_number }}
                  </h4>
                </div>
                <i>В соответствии с номером карты из учетной системы*
                  применяется скидка</i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <camera
      v-if="showCamera"
      @validatingData="validatingData($event)"
      @responseFromScanner="responseFromScanner"
    />
  </div>
</template>

<script>
import Camera from '@/components/v-scanner.vue'
export default {
  name: 'ScannerPage',
  components: {
    Camera,
  },
  data() {
    return {
      showCamera: true,
      form: {
        category: null,
        sub_category: null,
        card_type: null,
        cardholder: '',
        account_number: '',
        card_number: '',
        date: '',
        full_parsed_text: [],
      },
    }
  },
  methods: {
    responseFromScanner(data) {
      console.log('responseFromScanner', data)
    },
    validatingData: function (data) {
      if (data.account_number) {
        this.form.account_number = data.account_number
      }
      if (data.card_number) {
        this.form.card_number = data.card_number
      }
      if (data.card_type) {
        this.form.card_type = data.card_type
      }
      if (data.cardholder) {
        this.form.cardholder = data.cardholder
      }
      if (data.category) {
        this.form.category = data.category
      }
      if (data.date) {
        this.form.date = data.date
      }
      if (data.sub_category) {
        this.form.sub_category = data.sub_category
      }
      if (data.full_parsed_text.length) {
        this.form.full_parsed_text = data.full_parsed_text
      }
      if (
        this.form.category &&
        (this.form.account_number || this.form.card_number)
      ) {
        this.showCamera = false
      }
    },
  },
}
</script>

<style>
@import url('https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css');
@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

body {
  background: #f9f9f9;
  font-family: 'Roboto', sans-serif;
}

.main-content {
  padding-top: 100px;
  padding-bottom: 100px;
}

.info-card {
  background: #fff;
  text-align: center;
  padding: 50px 30px;
  margin-bottom: 30px;
  border-radius: 3px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.info-card .info-card_icon {
  height: 125px;
  width: 125px;
  margin: 0 auto 50px auto;
  border: 5px solid #4caf50;
  border-radius: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.info-card .info-card_icon i {
  font-size: 50px;
  color: #4caf50;
}

.info-card .info-card_icon .info-card_img-icon {
  height: 60px;
  width: 60px;
  object-fit: contain;
}

.info-card .info-card_label {
  margin-bottom: 15px;
}

.info-card .info-card_message {
  margin-bottom: 15px;
}

.info-card .btn {
  background: #03a9f4;
  border-color: #03a9f4;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

.info-card--success .info-card_icon {
  border-color: #4caf50;
}

.info-card--success .info-card_icon i {
  color: #4caf50;
}

.info-card--danger .info-card_icon {
  border-color: #f44336;
}

.info-card--danger .info-card_icon i {
  color: #f44336;
}

.info-card--warning .info-card_icon {
  border-color: #ff9800;
}

.info-card--warning .info-card_icon i {
  color: #ff9800;
}
</style>
