<template>
  <div class="camera">
    <div class="wrapper">
      <button @click="scanGo">
        Сканировать
      </button>
      <div class="video-container">
        <video
          v-show="isCameraOpen"
          ref="camera"
          class="camera-video"
          :width="width"
          autoplay
          playsinline
        />
        <canvas
          id="photoTaken"
          ref="canvas"
          class="canvas-photo"
          :width="width"
          :height="height"
        />
        <svg
          id="svgOne"
          :style="{ width: width, height: height }"
        />
      </div>
      <i>{{ resDataComputed }}</i>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Camera',
  emits: ['responseFromScanner'],
  data() {
    return {
      scan: false,
      resData: [],
      isCameraOpen: true,
      isPhotoTaken: false,
      timer: null,
      width: 0,
      height: 0,
      is_draw: false,
      square: { x: 0, y: 0, w: 0, h: 0 },
    }
  },
  computed: {
    resDataComputed() {
      let res = 'Сканирую...'
      if (this.resData.length) {
        res = this.resData.join(', ')
      }
      return res
    },
  },
  async mounted() {
    await this.createCameraElement()
    this.width = Math.min(window.innerWidth, 1080)
    console.log(this.width)
  },
  beforeUnmount() {
    this.stopCameraStream()
    clearInterval(this.timer)
  },
  methods: {
    async scanGo() {
      this.getData()
    },
    createCameraElement() {
      const constraints = (window.constraints = {
        audio: false,
        video: {
          facingMode: 'environment',
        },
      })
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.$refs.camera.srcObject = stream
        })
        .catch((error) => {
          alert(
            error,
            "May the browser didn't support or there is some errors."
          )
        })
    },
    drawSVG: function () {
      let width = 0.9 * this.width
      let height = (width * 54) / 86

      let svgns = 'http://www.w3.org/2000/svg'
      let rect = document.createElementNS(svgns, 'rect')
      rect.setAttributeNS(null, 'x', String((this.width - width) / 2))
      rect.setAttributeNS(null, 'y', String((this.height - height) / 2))
      rect.setAttributeNS(null, 'width', String(width))
      rect.setAttributeNS(null, 'height', String(height))
      rect.setAttributeNS(null, 'stroke', '#fff900')
      rect.setAttributeNS(null, 'fill', 'none')
      rect.setAttributeNS(null, 'stroke-width', '2')
      document.getElementById('svgOne').appendChild(rect)
      console.log({
        x: String((this.width - width) / 2),
        y: String((this.height - height) / 2),
        w: String(width),
        h: String(height),
      })
      return {
        x: String((this.width - width) / 2),
        y: String((this.height - height) / 2),
        w: String(width),
        h: String(height),
      }
    },
    stopCameraStream() {
      const tracks = this.$refs.camera.srcObject.getTracks()
      tracks.forEach((track) => {
        track.stop()
      })
      console.log('CameraClosed')
    },
    takePhoto() {
      this.isPhotoTaken = !this.isPhotoTaken
      let context = this.$refs.canvas.getContext('2d')
      let photoFromVideo = this.$refs.camera
      this.height =
        photoFromVideo.videoHeight / (photoFromVideo.videoWidth / this.width)
      if (this.height && !this.is_draw) {
        this.square = this.drawSVG()
        this.is_draw = true
      }
      context.drawImage(photoFromVideo, 0, 0, this.width, this.height)
    },
    downloadImage() {
      return document
        .getElementById('photoTaken')
        .toDataURL('image/jpeg')
        .replace('image/jpeg', 'image/octet-stream')
    },
    dataURLtoFile: function (dataurl, filename) {
      let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },
    updateData: function (data) {
      console.log('updateData', data)
      this.resData = data.full_parsed_text
      this.$emit('responseFromScanner', data)
    },
    getData: async function () {
      this.takePhoto()
      let src = this.downloadImage()
      let file = this.dataURLtoFile(src, 'image.jpeg')
      let formData = new FormData()
      formData.append('image', file)
      formData.append('x', this.square.x)
      formData.append('y', this.square.y)
      formData.append('h', this.square.h)
      formData.append('w', this.square.w)
      console.log('formData', formData)
      let updateData = this.updateData

      axios({
        method: 'post',
        url: 'https://scaner3.zedform.ru/api/processing',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(function (response) {
          //handle success
          console.log('Все ок')
          updateData(response.data)
        })
        .catch(function (response) {
          //handle error
          console.log('Ошибонька')
          console.log(response)
        })
    },
  },
}
</script>

<style>
.camera {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 90%;
  background-color: white;
}

button {
  position: absolute;
  right: 0;
  top: 0;
  width: 300px;
  height: 50px;
  background: #228740;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  z-index: 9;
}

.video-container {
  display: flex;
  flex-direction: column;
}

img {
  position: absolute;
}

#svgOne {
  position: absolute;
  border-radius: 5%;
}

#svgOne rect {
  border-radius: 5%;
}

/*#photoTaken {*/
/*  display: none;*/
/*}*/

i {
  position: absolute;
  left: 0;
  padding: 10px;
  top: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.7);
  color: red !important;
  font-size: 20px !important;
  line-height: normal;
}
</style>
